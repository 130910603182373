//@flow

const AppSettings = {
    "localhost": {
        apiUrl: "http://localhost:10090"
    },

    "192.168.5.94": {
        apiUrl: "http://192.168.5.94:8097"
    },

    "mdb-fe.gbbt.ru": {
        apiUrl: "https://mdb.gbbt.ru"
    },

    "mdb-fe.ptbt.site": {
        apiUrl: "https://mdb.ptbt.site"
    },

    "mdb-fe.ptbt.ru": {
        apiUrl: "https://mdb.ptbt.ru"
    },

    "mdb-fe-dev.ptbt.ru": {
        apiUrl: "https://mdb-dev.ptbt.ru"
    }
};


export default AppSettings