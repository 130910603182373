import React from 'react'
import PropTypes from 'prop-types'
import Meal from "../../domain/Meal";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    table: {
        minWidth: 200
    },
    noBorder: {
        borderBottom: 'none'
    },
    left: {
        textAlign: 'left'
    },
    right: {
        textAlign: 'right'
    }
});

class ShortMealDetailsTable extends React.Component {

    static propTypes = {
        meal: PropTypes.instanceOf(Meal),
        weight: PropTypes.number
    };

    static formatParam(value : number): string {
        if (!value)
            return "0";

        if(Math.round(value) !== value) {
            return value.toFixed(2);
        }

        return value;
    }

    render() {
        const meal: Meal = this.props.meal;
        const weight: number = this.props.weight;
        const classes: number = this.props.classes;

        return (
            <Table className={classes.table} size="small">
                <TableBody>
                    <TableRow key="calories">
                        <TableCell className={classes.left}>Калории</TableCell>
                        <TableCell className={classes.right}>{ShortMealDetailsTable.formatParam(meal.calories*weight/100)} ККал</TableCell>
                    </TableRow>
                    <TableRow key="prot">
                        <TableCell className={classes.left}>Белки</TableCell>
                        <TableCell className={classes.right}>{ShortMealDetailsTable.formatParam(meal.protein*weight/100)} г</TableCell>
                    </TableRow>
                    <TableRow key="fat">
                        <TableCell className={classes.left}>Жиры</TableCell>
                        <TableCell className={classes.right}>{ShortMealDetailsTable.formatParam(meal.fat*weight/100)} г</TableCell>
                    </TableRow>
                    <TableRow key="carb">
                        <TableCell className={[classes.noBorder, classes.left]}>Углеводы</TableCell>
                        <TableCell className={[classes.noBorder, classes.right]}>{ShortMealDetailsTable.formatParam(meal.carbohydrate*weight/100)} г</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
}

export default withStyles(styles)(ShortMealDetailsTable);