// @flow

import {array} from "prop-types";
import Meal from "../domain/Meal";
import MenuItem from "../domain/MenuItem";
import Menu from "../domain/Menu";
import ErrorDescription from "../domain/ErrorDescription";
import * as _ from 'lodash'

class MealDB {

    contextUrl: string;

    constructor(url: string, contextId: string) {
        this.contextUrl = url + '/context/' + contextId;
    }

    searchMeals(searchString: string, limit: number): Promise<array<Meal>> {
        return fetch(this.contextUrl + '/searchMeals?q=' + encodeURI(searchString) + '&limit=' + limit)
            .then(MealDB._checkResponse)
            .then((response) => response.json())
            .then((responseJson) => _.map(responseJson, MealDB._parseMeal))
    }

    getRecent(limit: number): Promise<array<MenuItem>> {
        return fetch(this.contextUrl + '/getRecent?limit=' + limit)
            .then(MealDB._checkResponse)
            .then((response) => response.json())
            .then((responseJson) => _.map(responseJson, MealDB._parseMenuItem))
    }

    getOwnMeals(limit: number, offset: number): Promise<array<Meal>> {
        return fetch(this.contextUrl + '/getOwnMeals?limit=' + limit + '&offset=' + offset)
            .then(MealDB._checkResponse)
            .then((response) => response.json())
            .then((responseJson) => _.map(responseJson, MealDB._parseMeal))
    }

    deleteOwnMeals(mealIdsToDelete: array<number>, limit: number, offset: number): Promise<array<Meal>> {
        return fetch(this.contextUrl + '/deleteOwnMeals?limit=' + limit + '&offset=' + offset,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(mealIdsToDelete)
            })
            .then(MealDB._checkResponse)
            .then((response) => response.json())
            .then((responseJson) => _.map(responseJson, MealDB._parseMeal))
    }

    getMenu(): Promise<Menu> {
        return fetch(this.contextUrl + '/getMenu')
            .then(MealDB._checkResponse)
            .then((response) => response.json())
            .then((responseJson) => MealDB._parseMenu(responseJson))
    }

    saveMenu(menu: Menu) {
        return fetch(this.contextUrl + "/saveMenu",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(menu)
            })
            .then(MealDB._checkResponse)
    }

    static _checkResponse(response) {
        if (response.status === 200)
            return response;

        return response.json().then(json => {throw new ErrorDescription(json.status)});
    }

    static _parseMeal(json: any) {
        return new Meal(json.id, json.title, json.protein, json.fat, json.carbohydrate, json.calories);
    }

    static _parseMenuItem(json: any) {
        return new MenuItem(_.uniqueId(), MealDB._parseMeal(json.meal), json.amount, json.comment)
    }

    static _parseMenu(json: any) {
        return new Menu(
            _.map(json.items,  MealDB._parseMenuItem),
            json.comment,
            json.time,
            json.water,
            json.closed
        )
    }
}

export default MealDB;