// @flow
import React from 'react'
import PropTypes from 'prop-types'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Meal from "../../domain/Meal";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {ListItemSecondaryAction, withStyles} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = theme => ({
    deleteButton: {
        color: '#f50057'
    }
});

class MealList extends React.Component {

    static propTypes = {
        meals: PropTypes.arrayOf(Meal),
        onSelectMeal: PropTypes.func,
        onDeleteMeal: PropTypes.func
    };

    handleMealSelect = (el : Meal) => {
        const {onSelectMeal} = this.props;
        if (onSelectMeal)
            onSelectMeal(el)
    };

    handleMealDelete = (el : Meal) => {
        const {onDeleteMeal} = this.props;
        if (onDeleteMeal)
            onDeleteMeal(el)
    };

    render() {
        const {meals} = this.props;
        const { classes } = this.props;
        const {onDeleteMeal} = this.props;

        return (
            <div>
                <List>
                    {meals.map((el: Meal) =>
                        <div key={el.id}>
                            <ListItem button>
                                <ListItemText primary={el.title} onClick={e => this.handleMealSelect(el)}/>
                                {onDeleteMeal &&
                                <ListItemSecondaryAction onClick={e => this.handleMealDelete(el)}>
                                    <DeleteIcon className={classes.deleteButton}/>
                                </ListItemSecondaryAction>
                                }
                            </ListItem>
                            <Divider/>
                        </div>
                    )}
                </List>
            </div>
        )
    }
}

export default withStyles(styles)(MealList);