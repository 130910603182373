//@flow
import React from 'react'
import PropTypes from 'prop-types'
import {AppContext} from "../../AppContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import MealList from "../MealList/MealList";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import ListEmptyMessage from "../ListEmptyMessage/ListEmptyMessage";
import * as _ from "lodash";
import Meal from "../../domain/Meal";
import {withStyles} from "@material-ui/core";
import MenuItem from "../../domain/MenuItem";

const styles = () => ({
    progress: {
        textAlign: 'center',
        marginTop: 50
    }
});

class RecentMeals extends React.Component {

    static contextType = AppContext;

    static propTypes = {
        onSelectMenuItem: PropTypes.func,
        focus: PropTypes.bool,
    };

    state = {
        showProgress: false,
        recentItems: null,
        recentItemsError: false,
        recentItemsLoaded: false
    };

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        return nextProps.focus;
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        this.loadRecentItems();
    }

    loadRecentItems = () => {
        if (this.state.recentItemsLoaded)
            return;

        this.setState(
            {...this.state, showProgress: true, recentItemsLoaded: true},
            () => {
                // noinspection JSDeprecatedSymbols
                this.context.mealdb.getRecent(50)
                    .then(result => {
                        this.setState({
                            ...this.state,
                            recentItemsError: false,
                            showProgress: false,
                            recentItems: result
                        })
                    })
                    .catch(() => {
                        this.setState({
                            ...this.state,
                            showProgress: false,
                            recentItemsError: true
                        })
                    })
            }
        );
    };

    handleMealSelect = (el : Meal) => {
        const {onSelectMenuItem} = this.props;
        if (!onSelectMenuItem)
            return;

        const {recentItems} = this.state;

        let menuItem: MenuItem = null;
        if (recentItems)
            menuItem = _.find(recentItems, (item : MenuItem) => item.meal.id === el.id);

        if (!menuItem)
            menuItem = new MenuItem(_.uniqueId(), el, 100, "");

        onSelectMenuItem(menuItem)
    };

    render() {
        const {showProgress} = this.state;
        const {recentItemsError} = this.state;
        const classes = this.props.classes;

        const recentItems : Array<Meal> = this.state.recentItems;
        const recentItemsEmpty = !recentItems || recentItems.length === 0;

        return (
            <div>
                {showProgress ? (
                    <div className={classes.progress}>
                        <CircularProgress/>
                    </div>
                ) : (
                    <div>
                        {!recentItemsEmpty && !recentItemsError &&
                        <MealList meals={_.map(recentItems, item => item.meal)} onSelectMeal={this.handleMealSelect}/>
                        }

                        {recentItemsEmpty && !recentItemsError &&
                        <ListEmptyMessage text="Список пуст"/>
                        }

                        {recentItemsError &&
                        <ErrorMessage text="Ошибка загрузки списка. Пожалуйста, повторите попытку."/>
                        }
                    </div>
                )}
            </div>
        )
    }
}

export default withStyles(styles)(RecentMeals);