// @flow
import React from "react"
import PropTypes from "prop-types"
import MenuItem from "../../domain/MenuItem";
import {withStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ShortMealDetailsTable from "../MealDetailsTable/ShortMealDetailsTable";

const styles = theme => ({
    root: {
        width: '100%',
    },
    mealTitle: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        flexBasis: '99%',
        textAlign: 'left'
    },
    mealAmount: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        flexBasis: '1%',
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    editButton: {
        backgroundColor: '#3f51b5'
    },
    deleteButton: {
        backgroundColor: '#f50057'
    }
});

class MenuList extends React.Component {

    static propTypes = {
        onEditItem: PropTypes.func,
        onRemoveItem: PropTypes.func,
        items: PropTypes.arrayOf(MenuItem),
        classes: PropTypes.object.isRequired
    };

    state = {
        deletedItemId: null,
    };

    handleEditItem = (item: MenuItem) => {
        const props = this.props;
        if (props.onEditItem) {
            props.onEditItem(item);
        }
    };

    handleRemoveItem = (id) => {
        const props = this.props;

        this.setState({...this.state, deletedItemId: id}, function(){
            setTimeout(() => {
                if (props.onRemoveItem) {
                    props.onRemoveItem(id);
                }
            }, 400)
        });
    };

    render() {

        const { classes } = this.props;

        const items = this.props.items;

        const itemsList = items.map((item : MenuItem) =>
            (<ExpansionPanel key={item.id}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.mealTitle}>{item.meal.title}</Typography>
                    <Typography className={classes.mealAmount}><b>{item.amount} г</b></Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <ShortMealDetailsTable meal={item.meal} weight={item.amount}/>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <Button variant="contained" size="small" color="primary" onClick={(e) => this.handleEditItem(item)} className={classes.editButton}>
                        Изменить
                        <EditIcon className={classes.rightIcon} />
                    </Button>
                    <Button variant="contained" size="small" color="secondary" onClick={(e) => this.handleRemoveItem(item.id)} className={classes.deleteButton}>
                        Удалить
                        <DeleteIcon className={classes.rightIcon} />
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>)
        );

        return (
            <div className="MenuList">
                {itemsList}
            </div>
        )
    }

}

export default withStyles(styles)(MenuList);