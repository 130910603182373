// @flow
import React from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from "lodash"
import './App.css';
import MenuList from "./components/MenuList/MenuList";
import NewMenuItemDialog from "./components/NewMenuItemDialog/NewMenuItemDialog";
import EditMenuItemDialog from "./components/EditMenuItemDialog/EditMenuItemDialog";
import SummaryDialog from "./components/SummaryDialog/SummaryDialog";
import MenuItem from "./domain/MenuItem";
import MealDB from './services/MealDB'
import {AppContext} from './AppContext'
import Icon from '@material-ui/core/Icon';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from "@material-ui/core/Divider";
import ErrorDescription from "./domain/ErrorDescription";
import CircularProgress from "@material-ui/core/CircularProgress";
import errorIcon from './images/icon_error.png'
import plusIcon from './images/icon_plus.png'
import acceptIcon from './images/icon_accept.png'
import Menu from "./domain/Menu";
import AppSettings from "./settings";

function getUrlParameterByName(name, defaultValue) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return defaultValue;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

const tgWebApp = window.Telegram.WebApp;

const styles = theme => ({
    appBar: {
      backgroundColor: '#636066'
    },
    savedMessage: {
        color: green[500],
        position: 'absolute',
        bottom: '50%',
        marginLeft: '-50px'
    },
    fab: {
        transition: 'all 500ms',
        position: 'fixed',
        zIndex:999,
        '&:hover': {
            opacity: 0.8,
            cursor: 'pointer'
        },
        '& img': {
            transition: 'all 500ms'
        }
    },
    fabBottom: {
        bottom: theme.spacing(4),
        right: theme.spacing(2),
        '& img': {
            width: 60
        }
    },
    fabCenter: {
        bottom: '40%',
        right: '50%',
        marginRight: '-50px',
        '& img': {
            width: 100
        }
    },
    fabHidden: {
        display: 'none'
    },
    flex: {
        flex: 1,
        textAlign: 'left'
    },
    listBar: {
        marginTop: 70
    },

    notificationText: {
        textAlign: 'center',
    },
    successNotificationText: {
        color: green[500],
    },
    errorNotificationText: {
        color: red[500],
    },

    notificationIcon: {
        textAlign: 'center',
        marginBottom: 0,
        marginTop: 20,
        fontSize: 100
    },
    successNotificationIcon: {
        color: green[500]
    },
    errorNotificationIcon: {
        color: red[500]
    },

    notificationComment: {
        textAlign: 'center',
        marginTop: 20,
    },

    loadError: {
        marginTop: 100
    },

    progress: {
        textAlign: 'center',
        marginTop: 100
    },

    progress1: {
        textAlign: 'center',
        marginTop: 30,
        marginBottom: 30
    }
});

const settings = AppSettings[window.location.hostname];

const mealDB = new MealDB(settings.apiUrl, getUrlParameterByName('c', 'test'));

class App extends React.Component {

    static propTypes = {
        login: PropTypes.string
    };

    static ACTIVE_STATE_EDIT = "edit";
    static ACTIVE_STATE_SAVING = "saving";
    static ACTIVE_STATE_SAVED = "saved";
    static ACTIVE_STATE_SAVE_ERROR = "save error";
    static ACTIVE_STATE_LOAD_ERROR = "load error";
    static ACTIVE_STATE_LOADING = "loading";
    static ACTIVE_STATE_SUMMARY = "summary";

    state = {
        menu: [],
        comment: null,
        time: null,
        newItem: false,
        editItem: null,
        errorDescription: {status: ""},
        saved: true,
        activeState: App.ACTIVE_STATE_LOADING
    };

    handleMenuItemCreate = (menuItem: MenuItem) => {
        let menu = this.state.menu;
        menu.push(menuItem);
        this.setState({...this.state, menu: menu, newItem: false, saved: false})
    };

    handleMenuItemRemove = (menuItemId: number) => {
        let menu = this.state.menu;
        menu = _.remove(menu, (item: MenuItem) => (item.id !== menuItemId));
        this.setState({...this.state, menu: menu, saved: false})
    };

    handleMenuItemEdit = (menuItem: MenuItem) => {
        this.setState({...this.state, editItem: menuItem, saved: false})
    };

    handleNewItemDialogOpen = (event) => {
        event.preventDefault();
        this.setState({...this.state, newItem: true})
    };

    handleNewItemDialogClose = () => {
        this.setState({...this.state, newItem: false})
    };

    handleEditItemDialogClose = () => {
        this.setState({...this.state, editItem: null})
    };

    handleSend = () => {
        this.setState({...this.state, activeState: App.ACTIVE_STATE_SUMMARY})
    };

    handleSave = (items: any, time: string, comment: string, water: number) => {
        const menu = new Menu(items, comment, time, water);

        this.setState(
            {...this.state, menu: items, comment: comment, time: time, water: water, activeState: App.ACTIVE_STATE_SAVING},
            () => {
                mealDB.saveMenu(menu)
                    .then(result => {
                        if (result.status === 200) {
                            this.setState({...this.state, activeState: App.ACTIVE_STATE_SAVED, saved: true}, () => tgWebApp.close());
                        } else
                            this.setState({...this.state, activeState: App.ACTIVE_STATE_SAVE_ERROR});
                    })
                    .catch((errorDescription) => {
                        this.setState({
                            ...this.state,
                            errorDescription: errorDescription,
                            activeState: App.ACTIVE_STATE_SAVE_ERROR
                        })
                    })
            }
        );
    };

    handleNotificationClose = () => {
        this.setState({...this.state, activeState: App.ACTIVE_STATE_EDIT})
    };

    handleSummaryClose = () => {
        this.setState({...this.state, activeState: App.ACTIVE_STATE_EDIT})
    };


    componentDidMount(): void {
        tgWebApp.ready();
        tgWebApp.expand();

        mealDB.getMenu()
            .then((result: Menu) => {
                this.setState(
                    {...this.state,
                        menu: result.items,
                        comment: result.comment,
                        time: result.time,
                        water: result.water,
                        activeState: result.closed ? App.ACTIVE_STATE_SAVED : App.ACTIVE_STATE_EDIT
                    })
            })
            .catch((errorDescription) => {
                this.setState({
                    ...this.state,
                    errorDescription: errorDescription,
                    activeState: App.ACTIVE_STATE_LOAD_ERROR
                })
            })
    }

    render() {
        const menu = this.state.menu;
        const saved = this.state.saved;
        const activeState = this.state.activeState;
        const comment = this.state.comment;
        const time = this.state.time;
        const water = this.state.water;
        const errorDescription: ErrorDescription = this.state.errorDescription;

        const {classes} = this.props;
        const context = {
            mealdb: mealDB
        };

        const totalCalories = Math.trunc(_(menu).map((item: MenuItem) => item.meal.calories * item.amount / 100).sum());

        const emptyList = activeState === App.ACTIVE_STATE_EDIT && menu.length === 0;
        const notEmptyList = activeState !== App.ACTIVE_STATE_LOADING && activeState !== App.ACTIVE_STATE_LOAD_ERROR && menu.length > 0;
        const fabClass = emptyList ? classes.fabCenter : (notEmptyList ? classes.fabBottom : classes.fabHidden);

        return (
            <div className="App">
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="button" color="inherit" className={classes.flex}>
                            {totalCalories} ККАЛ
                        </Typography>
                        <Button href="#" color="inherit" onClick={this.handleSend} disabled={menu.length === 0} className={menu.length === 0 || saved ? '' : 'blink'}>
                            Отправить&nbsp;
                            <Icon>send</Icon>
                        </Button>
                    </Toolbar>
                </AppBar>

                <AppContext.Provider value={context}>
                    {activeState === App.ACTIVE_STATE_LOADING &&
                    <div className={classes.progress}>
                        <CircularProgress/>
                    </div>
                    }

                    {activeState === App.ACTIVE_STATE_LOAD_ERROR &&
                    <div className={classes.loadError}>
                        <div className={classNames([classes.notificationIcon, classes.errorNotificationIcon])}>
                            <img src={errorIcon} alt="Ошибка"/>
                        </div>
                        <div className={classNames([classes.notificationText, classes.errorNotificationText])}>
                            Ошибка загрузки данных из бота
                        </div>
                        <Divider/>
                        <div className={classes.notificationComment}>
                            При загрузке данных из бота произошла ошибка<br/>
                            <p><b>{errorDescription.status}</b></p>
                            Если ошибка повторяется, обратитесь в службу поддержки PrimetimeBot.
                        </div>
                    </div>
                    }

                    <div className={classNames(classes.fab, fabClass)} onClick={this.handleNewItemDialogOpen}>
                        <img src={plusIcon} alt="Добавить"/>
                    </div>

                    {notEmptyList &&
                    <div className={classes.listBar}>
                        <MenuList items={menu} onRemoveItem={this.handleMenuItemRemove}
                                  onEditItem={this.handleMenuItemEdit}/>
                    </div>
                    }
                    <NewMenuItemDialog open={this.state.newItem} onClose={this.handleNewItemDialogClose}
                                       onSave={this.handleMenuItemCreate}/>
                    <EditMenuItemDialog open={this.state.editItem != null} menuItem={this.state.editItem}
                                        onClose={this.handleEditItemDialogClose}
                                        onSave={this.handleEditItemDialogClose}/>
                </AppContext.Provider>

                <SummaryDialog
                    open={activeState === App.ACTIVE_STATE_SUMMARY}
                    items={menu}
                    comment={comment}
                    time={time}
                    water={water}
                    onClose={this.handleSummaryClose}
                    onSave={this.handleSave}
                />

                <Dialog
                    id="notificationDialog"
                    open={activeState === App.ACTIVE_STATE_SAVED || activeState === App.ACTIVE_STATE_SAVE_ERROR || activeState === App.ACTIVE_STATE_SAVING}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText>
                            {activeState === App.ACTIVE_STATE_SAVING &&
                            <div>
                                <div className={classes.progress1}>
                                    <CircularProgress/>
                                </div>
                                <div
                                    className={classNames([classes.notificationText, classes.successNotificationText])}>
                                    Сохранение...
                                </div>
                            </div>
                            }
                            {activeState === App.ACTIVE_STATE_SAVED &&
                            <div>
                                <div
                                    className={classNames([classes.notificationIcon, classes.successNotificationIcon])}>
                                    <img src={acceptIcon} alt="OK"/>
                                </div>
                                <div
                                    className={classNames([classes.notificationText, classes.successNotificationText])}>
                                    Изменения успешно сохранены
                                </div>
                                <Divider/>
                                <div className={classes.notificationComment}>
                                    Вернитесь в бота для продолжения
                                </div>
                            </div>
                            }
                            {activeState === App.ACTIVE_STATE_SAVE_ERROR &&
                            <div>
                                <div className={classNames([classes.notificationIcon, classes.errorNotificationIcon])}>
                                    <img src={errorIcon} alt="Ошибка"/>
                                </div>
                                <div className={classNames([classes.notificationText, classes.errorNotificationText])}>
                                    Ошибка сохранения изменений
                                </div>
                                <Divider/>
                                <div className={classes.notificationComment}>
                                    К сожалению, при отправке данных в бота произошла ошибка<br/>
                                    <p><b>{errorDescription.status}</b></p>
                                    Попробуйте закрыть это окно и сохранить изменения еще раз. Если ошибка сохраняется,
                                    обратитесь в службу поддержки PrimetimeBot.
                                </div>
                            </div>

                            }
                        </DialogContentText>
                    </DialogContent>
                    {activeState === App.ACTIVE_STATE_SAVE_ERROR &&
                    <DialogActions>
                        <Button onClick={this.handleNotificationClose} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                    }
                </Dialog>

            </div>
        )
    }

}

export default withStyles(styles, {withTheme: true})(App)
