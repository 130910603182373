import React from 'react'
import PropTypes from 'prop-types'
import Meal from "../../domain/Meal";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    table: {
        minWidth: 200,
    },
});

class FullMealDetailsTable extends React.Component {

    static propTypes = {
        meal: PropTypes.instanceOf(Meal),
        weight: PropTypes.number
    };

    static formatParam(value : number): string {
        if (!value)
            return "0";

        if(Math.round(value) !== value) {
            return value.toFixed(2);
        }

        return value;
    }

    render() {
        const meal: Meal = this.props.meal;
        const weight: number = this.props.weight;
        const classes: number = this.props.classes;

        return (
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell>100 г</TableCell>
                        <TableCell><b>{weight} г</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key="calories">
                        <TableCell>Калории</TableCell>
                        <TableCell>{FullMealDetailsTable.formatParam(meal.calories)} ККал</TableCell>
                        <TableCell><b>{FullMealDetailsTable.formatParam(meal.calories*weight/100)} ККал</b></TableCell>
                    </TableRow>
                    <TableRow key="prot">
                        <TableCell>Белки</TableCell>
                        <TableCell>{FullMealDetailsTable.formatParam(meal.protein)} г</TableCell>
                        <TableCell><b>{FullMealDetailsTable.formatParam(meal.protein*weight/100)} г</b></TableCell>
                    </TableRow>
                    <TableRow key="fat">
                        <TableCell>Жиры</TableCell>
                        <TableCell>{FullMealDetailsTable.formatParam(meal.fat)} г</TableCell>
                        <TableCell><b>{FullMealDetailsTable.formatParam(meal.fat*weight/100)} г</b></TableCell>
                    </TableRow>
                    <TableRow key="carb">
                        <TableCell>Углеводы</TableCell>
                        <TableCell>{FullMealDetailsTable.formatParam(meal.carbohydrate)} г</TableCell>
                        <TableCell><b>{FullMealDetailsTable.formatParam(meal.carbohydrate*weight/100)} г</b></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
}

export default withStyles(styles)(FullMealDetailsTable);