//@flow
import React from 'react'
import PropTypes from 'prop-types'
import {red} from "@material-ui/core/colors";
import {withStyles} from "@material-ui/core";
import logo from '../../images/icon_error.png'

const styles = () => ({
    root: {
        marginTop: 50,
    },
    errorText: {
        color: red[500],
        textAlign: 'center',
    },
    errorIcon: {
        color: red[500],
        textAlign: 'center',
        marginBottom: 20,
        fontSize: 70
    }
});

class ErrorMessage extends React.Component {

    static propTypes = {
        text: PropTypes.string,
    };

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.errorIcon}>
                    <img src={logo} alt="Ошибка"/>
                </div>
                <div className={classes.errorText}>
                    {this.props.text}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(ErrorMessage);
