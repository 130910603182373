//@flow
import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import EditMenuItemForm from '../EditMenuItemForm/EditMenuItemForm'
import MenuItem from "../../domain/MenuItem";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

const styles = {
    appBar: {
        position: 'relative',
        backgroundColor: '#636066'
    },
    flex: {
        flex: 1,
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class EditMenuItemDialog extends React.Component {

    static propTypes = {
        menuItem: PropTypes.instanceOf(MenuItem),
        open: PropTypes.bool,
        onClose: PropTypes.func,
        onSave: PropTypes.func,
    };

    handleClose = () => {
        const props = this.props;

        if (props.onClose)
            props.onClose();
    };

    handleSaveMenuItem = (menuItem : MenuItem) => {
        const props = this.props;

        if (props.onSave)
            props.onSave(menuItem);
    };

    render() {
        const { classes } = this.props;
        const menuItem = this.props.menuItem;
        const open = this.props.open;

        return (
            <div>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Изменить продукт
                            </Typography>
                            <IconButton href="#" color="inherit" onClick={this.handleClose}>
                                <Icon>close</Icon>
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <EditMenuItemForm menuItem={menuItem} onSave={this.handleSaveMenuItem} focus={open}/>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(EditMenuItemDialog);