//@flow
import React from 'react'
import PropTypes from 'prop-types'
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search'
import CircularProgress from "@material-ui/core/CircularProgress";
import MealList from "../MealList/MealList";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import NotFoundMessage from "../NotFoundMessage/NotFoundMessage";
import {grey} from "@material-ui/core/colors";
import {withStyles} from "@material-ui/core";
import Meal from "../../domain/Meal";
import {AppContext} from "../../AppContext";

const styles = () => ({
    searchBar: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: grey[50]
    },
    searchInput: {
        marginLeft: 8,
        flex: 1,
    },
    searchButton: {
        padding: 8,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    progressBar: {
        textAlign: 'center',
        marginTop: 50
    }
});

class SearchMeal extends React.Component {

    static contextType = AppContext;

    static propTypes = {
        onSelectMeal: PropTypes.func,
        focus: PropTypes.bool,
    };

    state = {
        searchError: false,
        showProgress: false,
        meals: []
    };

    constructor(props: P, context: any) {
        super(props, context);
        this.searchField = React.createRef();
    }

    handleSearch = () => {
        const searchString = this.searchField.value;

        if (searchString.length === 0)
            return;

        this.setState(
            {...this.state, showProgress: true},
            () => {
                // noinspection JSDeprecatedSymbols
                this.context.mealdb.searchMeals(searchString, 100)
                    .then(result => {
                        this.setState({
                            ...this.state,
                            searchError: false,
                            showProgress: false,
                            meals: result
                        })
                    })
                    .catch(() => {
                        this.setState({
                            ...this.state,
                            showProgress: false,
                            searchError: true
                        })
                    })
            }
        );
    };

    handleMealSelect = (meal : Meal) => {
        const onSelectMeal = this.props.onSelectMeal;
        if (onSelectMeal)
            onSelectMeal(meal);
    };

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        return this.state.showProgress !== nextState.showProgress;
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (!this.searchField)
            return;

        if (this.props.focus && this.state.meals.length === 0) {
            this.searchField.focus();
        } else {
            this.searchField.blur();
        }
    }

    render() {
        const classes = this.props.classes;
        const searchString = !this.searchField ? "" : this.searchField.value;

        const {showProgress} = this.state;
        const {searchError} = this.state;

        const meals : Array<Meal> = this.state.meals;
        const mealsEmpty = meals.length === 0;
        const mealsFound = !mealsEmpty && !searchError;
        const noMealsFound = mealsEmpty && searchString && searchString.length > 0 && !searchError;

        return (
            <div>
                <div className={classes.searchBar}>
                    <InputBase
                        inputRef={(ref) => this.searchField = ref}
                        className={classes.searchInput}
                        placeholder="Название продукта"
                        type="search"
                    />
                    <Divider className={classes.divider} />
                    <IconButton color="primary" className={classes.searchButton} aria-label="Поиск" onClick={this.handleSearch}>
                        <SearchIcon />
                    </IconButton>
                </div>

                {showProgress ? (
                <div className={classes.progressBar}>
                    <CircularProgress/>
                </div>
                ) : (
                <div>
                    {mealsFound &&
                    <MealList meals={meals} onSelectMeal={this.handleMealSelect}/>
                    }
                    {noMealsFound &&
                    <NotFoundMessage text="Продукты с таким названием не найдены"/>
                    }
                    {searchError &&
                    <ErrorMessage text="Ошибка поиска продуктов. Пожалуйста, повторите поиск."/>
                    }
                </div>
                )}
            </div>
        )
    }
}

export default withStyles(styles)(SearchMeal);