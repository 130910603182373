//@flow
import React from 'react'
import PropTypes from 'prop-types'
import {AppContext} from "../../AppContext";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import {withStyles} from "@material-ui/core";
import green from "@material-ui/core/es/colors/green";

const styles = theme => ({
    margin: {
        margin: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    buttonGreen: {
        backgroundColor: '#46bd13',
        '&:hover': {
            backgroundColor: green[600],
        }
    },
    buttonPink: {
        backgroundColor: '#d5469e',
        '&:hover': {
            backgroundTransparency: 90,
        }
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    right: {
        float: 'right'
    },
    menuDetails: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto'
    }
});

class NewMealFormStringStep extends React.Component {

    static maxWeightLen = 50;

    static contextType = AppContext;

    static propTypes = {
        focus: PropTypes.bool,
        title: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
        onBack: PropTypes.func,
        onNext: PropTypes.func
    };

    valueInput;

    constructor(props: P, context: any) {
        super(props, context);
        this.valueInput = React.createRef();
        this.state = {
            value: props.value == null ? 0 : props.value
        }
    }

    handleValueChange = (e) => {
        let value = e.target.value;
        if (value.length > NewMealFormStringStep.maxWeightLen)
            value = value.substring(0, NewMealFormStringStep.maxWeightLen);
        this.setState({value: value})
    };

    handleBack = () => {
        if (this.props.onBack)
            this.props.onBack(this.state.value)
    };

    handleNext = () => {
        if (this.props.onNext)
            this.props.onNext(this.state.value)
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (this.props.focus && this.valueInput) {
            this.valueInput.focus();
        }
    }

    render() {
        const {title, label, focus, classes} = this.props;
        const {value} = this.state;

        return (
            <div>
                <Typography variant="h5" component="h3">
                    {title}
                </Typography>

                <TextField
                    inputRef={(ref) => this.valueInput = ref}
                    className={classes.marginTop}
                    label={label}
                    fullWidth
                    autoFocus={focus}
                    required={true}
                    value={value}
                    onChange={this.handleValueChange}
                />

                <div className={classes.right}>
                    <Button variant="contained" className={classNames(classes.button, classes.buttonPink)} color="secondary"
                            onClick={this.handleBack}>
                        <Icon className={classNames(classes.iconSmall, classes.leftIcon)}>arrow_back</Icon>
                        Назад
                    </Button>

                    <Button
                        variant="contained"
                        className={classNames(classes.button, classes.buttonGreen)}
                        color="primary"
                        disabled={value.length === 0}
                        onClick={this.handleNext}
                    >
                        Далее
                        <Icon className={classNames(classes.iconSmall, classes.rightIcon)}>arrow_forward</Icon>
                    </Button>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(NewMealFormStringStep);