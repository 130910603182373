import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import SwipeableViews from 'react-swipeable-views';
import SelectMenuItem from '../SelectMenuItem/SelectMenuItem'
import EditMenuItemForm from '../EditMenuItemForm/EditMenuItemForm'
import MenuItem from "../../domain/MenuItem";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import NewMealForm from "../NewMealForm/NewMealForm";
import * as _ from 'lodash'


const styles = {
    appBar: {
        position: 'relative',
        backgroundColor: '#636066'
    },
    flex: {
        flex: 1,
    }
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class NewMenuItemDialog extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        onClose: PropTypes.func,
        onSave: PropTypes.func,
    };

    state = {
        activeStep: 0,
        menuItem: null
    };

    handleClose = (event) => {
        const props = this.props;

        this.setState({menuItem: null, activeStep: 0});

        if (props.onClose)
            props.onClose();
    };

    handleMenuItemSelect = (menuItem : MenuItem) => {
        this.setState({menuItem: menuItem, activeStep: 2});
    };

    handleCancelCreateMenuItem = () => {
        this.setState({menuItem: null, activeStep: 0});
    };

    handleSaveMenuItem = (menuItem : MenuItem) => {
        const props = this.props;

        this.setState({menuItem: null, activeStep: 0});

        if (props.onSave)
            props.onSave(menuItem);
    };

    handleCreateNewMeal = () => {
        this.setState({...this.state, activeStep: 1})
    };

    handleCancelMealCreate = () => {
        this.setState({...this.state, activeStep: 0})
    };

    handleNewMealCreate = (meal: Meal) => {
        this.setState({...this.state, menuItem: new MenuItem(_.uniqueId(), meal, 100, ""), activeStep: 2})
    };

    render() {
        const { classes } = this.props;
        const activeStep: number = this.state.activeStep;
        const menuItem : MenuItem = this.state.menuItem;
        const open = this.props.open;

        return (
            <div>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Новый продукт
                            </Typography>
                            <IconButton href="#" color="inherit" onClick={this.handleClose}>
                                <Icon>close</Icon>
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <SwipeableViews index={activeStep}>
                        <div>
                            <SelectMenuItem onSelectMenuItem={this.handleMenuItemSelect} onNewMenuItem={this.handleCreateNewMeal} focus={open && activeStep === 0}/>
                        </div>
                        <div>
                            <NewMealForm onBack={this.handleCancelMealCreate} onReady={this.handleNewMealCreate} focus={open && activeStep === 1}/>
                        </div>
                        <div>
                            <EditMenuItemForm menuItem={menuItem} onCancel={this.handleCancelCreateMenuItem} onSave={this.handleSaveMenuItem} focus={open && activeStep === 2}/>
                        </div>
                    </SwipeableViews>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(NewMenuItemDialog);