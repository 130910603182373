// @flow
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Icon from '@material-ui/core/Icon';
import Meal from '../../domain/Meal'
import green from "@material-ui/core/es/colors/green";
import MenuItem from "../../domain/MenuItem";
import FullMealDetailsTable from "../MealDetailsTable/FullMealDetailsTable";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    margin: {
        margin: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    buttonGreen: {
        backgroundColor: '#46bd13',
        '&:hover': {
            backgroundColor: green[600],
        }
    },
    buttonPink: {
        backgroundColor: '#d5469e',
        '&:hover': {
            backgroundTransparency: 90,
        }
    },
    marginTop: {
        marginTop: theme.spacing(1)
    },
    right: {
        float: 'right'
    },
    menuDetails: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto'
    }
});

class EditMenuItemForm extends React.Component {

    static propTypes = {
        menuItem: PropTypes.instanceOf(MenuItem),
        onCancel: PropTypes.func,
        onSave: PropTypes.func,
        focus: PropTypes.bool
    };

    static maxWeightLen = 6;

    weightInput;

    constructor(props: P, context: any) {
        super(props, context);
        this.weightInput = React.createRef();
        this.state = {
            weight: props.menuItem == null ? 0 : props.menuItem.amount
        }
    }

    handleCancel = () => {
        const onCancel = this.props.onCancel;
        if (onCancel)
            onCancel();
    };

    handleSave = () => {
        const menuItem = this.props.menuItem;
        menuItem.amount = this.state.weight;
        menuItem.comment = "Комментарий";

        const onSave = this.props.onSave;
        if (onSave)
            onSave(menuItem);
    };

    handleWeightChange = (e) => {
        let weight = e.target.value;
        if (weight.length > EditMenuItemForm.maxWeightLen)
            weight = weight.substring(0, EditMenuItemForm.maxWeightLen);
        this.setState({weight: weight})
    };

    getWeight = (weight : string) => {
        return weight.length === 0 ? 0 : parseFloat(weight)
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (this.props.focus && this.weightInput) {
            this.weightInput.focus();
        }
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        this.setState({ weight: nextProps.menuItem == null ? 0 : nextProps.menuItem.amount });
    }

    render() {
        if (!this.props.menuItem)
            return (<div></div>);

        const meal : Meal = this.props.menuItem.meal;
        const classes = this.props.classes;
        const weight = this.state.weight;

        const showBackButton = this.props.onCancel;

        if (!meal)
            return (<div/>);

        return (
            <div className={classes.margin}>
                <Typography variant="h5" component="h3">
                    {meal.title}
                </Typography>

                <TextField
                    inputRef={(ref) => this.weightInput = ref}
                    className={classes.marginTop}
                    label="Вес продукта"
                    fullWidth
                    autoFocus={this.props.focus}
                    type="number"
                    required={true}
                    value={this.state.weight}
                    onChange={this.handleWeightChange}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">Гр</InputAdornment>,
                    }}
                />

                <div className={classes.right}>
                    {showBackButton &&
                    <Button variant="contained" className={classNames(classes.button, classes.buttonPink)} color="secondary" onClick={this.handleCancel}>
                        <Icon className={classNames(classes.iconSmall, classes.leftIcon)}>arrow_back</Icon>
                        Назад
                    </Button>
                    }
                    <Button
                        variant="contained"
                        className={classNames(classes.button, classes.buttonGreen)}
                        color="primary"
                        disabled={this.getWeight(weight) <= 0}
                        onClick={this.handleSave}
                    >
                        Сохранить
                        <Icon className={classNames(classes.iconSmall, classes.rightIcon)}>save</Icon>
                    </Button>
                </div>

                <div>
                    <Paper className={classes.menuDetails}>
                        <FullMealDetailsTable meal={meal} weight={this.getWeight(weight)}/>
                    </Paper>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(EditMenuItemForm);