// @flow
class Meal {

    id: number;
    title: string;
    protein: number;
    fat: number;
    carbohydrate: number;
    calories: number;

    constructor(id : number, title: string, protein: number, fat: number, carbohydrate: number, calories: number) {
        this.id = id;
        this.title = title;
        this.protein = protein;
        this.fat = fat;
        this.carbohydrate = carbohydrate;
        this.calories = calories;
    }
}

export default Meal;