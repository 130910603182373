//@flow
import React from 'react'
import PropTypes from 'prop-types'
import {AppContext} from "../../AppContext";
import SwipeableViews from "react-swipeable-views";
import NewMealFormNumberStep from "./NewMealFormNumberStep";
import NewMealFormStringStep from "./NewMealFormStringStep";
import {withStyles} from "@material-ui/core";
import Meal from "../../domain/Meal";

const styles = theme => ({
    form: {
        margin:10
    }
});

class NewMealForm extends React.Component {

    static contextType = AppContext;

    static propTypes = {
        focus: PropTypes.bool,
        onBack: PropTypes.func,
        onReady: PropTypes.func
    };

    state = {
        activeStep: 0,
        title: '',
        protein: null,
        fat: null,
        carb: null,
        calories: null
    };

    handleNext = (value) => {
        const step = this.state.activeStep;
        const newState = this.updateValue(this.state, value, step);

        if (step === 4 && this.props.onReady)
            this.props.onReady(new Meal(null, newState.title, newState.protein, newState.fat, newState.carb, newState.calories));

        this.setState({...newState, activeStep: Math.min(this.state.activeStep + 1, 4)})
    };

    handleBack = (value) => {
        const step = this.state.activeStep;
        const newState = this.updateValue(this.state, value, step);

        if (step === 0 && this.props.onBack)
            this.props.onBack();

        this.setState({...newState, activeStep: Math.max(this.state.activeStep - 1, 0)})
    };

    updateValue = (state, value, step) => {
        const newState = {...state};
        switch (step) {
            case 0: newState.title = value; break;
            case 1: newState.protein = parseFloat(value); break;
            case 2: newState.fat = parseFloat(value); break;
            case 3: newState.carb = parseFloat(value); break;
            default: newState.calories = parseFloat(value);
        }
        return newState;
    };

    render() {
        const {activeStep, title, protein, fat, carb, calories} = this.state;
        const {focus} = this.props;
        const {classes} = this.props;

        return (
            <div className={classes.form}>
                <SwipeableViews index={activeStep} disabled={true}>
                    <div>
                        <NewMealFormStringStep title="Название продукта" label="Введите название продукта" focus={focus && activeStep === 0} value={title} onBack={this.handleBack} onNext={this.handleNext}/>
                    </div>
                    <div>
                        <NewMealFormNumberStep title="Содержание белков" label="Сколько белков в 100 гр продукта?" ador="Гр" focus={focus && activeStep === 1} value={protein} onBack={this.handleBack} onNext={this.handleNext}/>
                    </div>
                    <div>
                        <NewMealFormNumberStep title="Жиров на 100г" label="Сколько жиров в 100 гр продукта?" ador="Гр" focus={focus && activeStep === 2} value={fat} onBack={this.handleBack} onNext={this.handleNext}/>
                    </div>
                    <div>
                        <NewMealFormNumberStep title="Углеводов на 100г" label="Сколько углеводов в 100 гр продукта?" ador="Гр" focus={focus && activeStep === 3} value={carb} onBack={this.handleBack} onNext={this.handleNext}/>
                    </div>
                    <div>
                        <NewMealFormNumberStep title="Калорий на 100г" label="Сколько калорий в 100 гр продукта?" ador="Кк" focus={focus && activeStep === 4} value={calories} onBack={this.handleBack} onNext={this.handleNext}/>
                    </div>
                </SwipeableViews>
            </div>
        )
    }
}

export default withStyles(styles)(NewMealForm);