// @flow
import React from 'react'
import PropTypes from 'prop-types'
import {AppContext} from '../../AppContext'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {withStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Meal from "../../domain/Meal";
import MenuItem from "../../domain/MenuItem";
import SearchMeal from "../SearchMeal/SearchMeal";
import RecentMeals from "../RecentMeals/RecentMeals";
import MyMeals from "../MyMeals/MyMeals";
import * as _ from 'lodash'

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 10, display: (props.visible ? '' : 'none') }}>
            {props.children}
        </Typography>
    );
}

const styles = () => ({

});

class SelectMenuItem extends React.Component {

    static contextType = AppContext;

    static propTypes = {
        onSelectMenuItem: PropTypes.func,
        onNewMenuItem: PropTypes.func,
        focus: PropTypes.bool,
    };

    state = {
        activeTab : 0,
    };

    handleTabChange = (event, value) => {
        this.setState({...this.state, activeTab: value, step1SearchError: false});
    };

    handleMealSelect = (el : Meal) => {
       this.handleSelectMenuItem(new MenuItem(_.uniqueId(), el, 100, ""));
    };

    handleSelectMenuItem = (menuItem: MenuItem) => {
        const {onSelectMenuItem} = this.props;
        if (!onSelectMenuItem)
            return;

        onSelectMenuItem(menuItem)
    };

    handleNewMenuItem = () => {
      if (this.props.onNewMenuItem)
          this.props.onNewMenuItem();
    };

    render() {
        const {activeTab} = this.state;

        return (
            <div>
                <Tabs value={activeTab} onChange={this.handleTabChange} >
                    <Tab label="Поиск" value={0} />
                    <Tab label="Недавние" value={1}/>
                    <Tab label="Мои" value={2}/>
                </Tabs>

                <TabContainer key="searchTab" visible={activeTab === 0}>
                    <SearchMeal focus={this.props.focus && activeTab === 0} onSelectMeal={this.handleMealSelect}/>
                </TabContainer>

                <TabContainer key="recentTab" visible={activeTab === 1}>
                    <RecentMeals focus={this.props.focus && activeTab === 1} onSelectMenuItem={this.handleSelectMenuItem}/>
                </TabContainer>

                <TabContainer key="myMealsTab" visible={activeTab === 2}>
                    <MyMeals focus={this.props.focus && activeTab === 2}
                             onSelectMeal={this.handleMealSelect}
                             onNewMenuItem={this.handleNewMenuItem}
                    />
                </TabContainer>
            </div>
        )
    }
}

export default withStyles(styles)(SelectMenuItem);