//@flow
import React from 'react'
import PropTypes from 'prop-types'
import {grey} from "@material-ui/core/colors";
import {withStyles} from "@material-ui/core";
import logo from '../../images/icon_search.png'

const styles = () => ({
    root: {
        marginTop: 50,
    },
    emptyText: {
        color: grey[500],
        textAlign: 'center',
    },
    emptyIcon: {
        textAlign: 'center',
        marginBottom: 20
    }
});

class NotFoundMessage extends React.Component {

    static propTypes = {
        text: PropTypes.string,
    };

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.emptyIcon}>
                    <img src={logo} alt="Поиск"/>
                </div>
                <div className={classes.emptyText}>
                    {this.props.text}
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(NotFoundMessage);
