//@flow
import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import MenuItem from "../../domain/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from '@material-ui/core/TextField';
import Table from "@material-ui/core/Table";
import Slide from "@material-ui/core/Slide";
import classNames from "classnames";
import Button from "@material-ui/core/Button";

const styles = theme => ({
    appBar: {
        position: 'relative',
        backgroundColor: '#636066'
    },
    flex: {
        flex: 1,
    },
    content: {
        margin: 10
    },
    time: {
        width: '100%'
    },
    comment: {
        width: '100%'
    },
    table: {
        marginBottom: 10,
    },
    leftColumn: {
        textAlign: 'left'
    },
    rightColumn: {
        textAlign: 'right'
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    right: {
        float: 'right',
        marginTop: 10
    },
    waterBar: {
        padding: 10,
        border: '1px solid rgba(0, 0, 0, 0.24)',
        margin: '5px 0 0 0',
        borderRadius: 3
    },
    waterLabel: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.54)'
    },
    waterSelected: {
        backgroundColor: '#00abfe'
    }
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

const WideToggleButtonGroup = withStyles({
    root: {
        width: '100%'
    }
})(ToggleButtonGroup);

const WideToggleButton = withStyles({
    root: {
        width: '100%'
    },
    selected: {
        backgroundColor: '#00a8f9!important'
    }
})(ToggleButton);

class SummaryDialog extends React.Component {

    static propTypes = {
        open: PropTypes.bool,
        items: PropTypes.arrayOf(MenuItem),
        comment: PropTypes.string,
        time: PropTypes.string,
        water: PropTypes.string,
        onClose: PropTypes.func,
        onSave: PropTypes.func
    };

    timeInput;
    commentInput;

    constructor(props: P, context: any) {
        super(props, context);
        this.timeInput = React.createRef();
        this.commentInput = React.createRef();
        this.state = {
            water: 0
        };
    }

    handleClose = () => {
        const props = this.props;

        if (props.onClose)
            props.onClose();
    };

    handleSave = () => {
        const props = this.props;

        if (props.onSave)
            props.onSave(props.items, this.timeInput.value, this.commentInput.value, this.state.water);
    };

    handleWater = (e, amount) => {
        this.setState({...this.state, water: amount})
    };

    asTime(val: number) {
        return val < 10 ? "0" + val : val;
    }

    formatParam(value : number): string {
        if (!value)
            return "0";
        return value.toFixed(0);
    }

    render() {
        const { classes } = this.props;
        const items = this.props.items;
        const open = this.props.open;
        const comment = this.props.comment;
        let water = this.state.water;
        if (!water) {
            water = (this.props.water ? this.props.water : 0).toString()
        }

        let currentTime = this.props.time;
        if (!currentTime) {
            const today = new Date();
            currentTime = this.asTime(today.getHours()) + ":" + this.asTime(today.getMinutes());
        }

        const calories = _.sum(_.map(items, i => i.meal.calories*i.amount/100));
        const prot = _.sum(_.map(items, i => i.meal.protein*i.amount/100));
        const fat = _.sum(_.map(items, i => i.meal.fat*i.amount/100));
        const carb = _.sum(_.map(items, i => i.meal.carbohydrate*i.amount/100));

        return (
            <div>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <Typography variant="h6" color="inherit" className={classes.flex}>
                                Итог
                            </Typography>
                            <IconButton href="#" color="inherit" onClick={this.handleClose}>
                                <Icon>close</Icon>
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <div className={classes.content}>

                        <Typography variant="h5" component="h3">
                            Общий КБЖУ
                        </Typography>

                        <Table className={classes.table} size="small">
                            <TableBody>
                                <TableRow key="calories">
                                    <TableCell className={classes.leftColumn}>Калории</TableCell>
                                    <TableCell className={classes.rightColumn}>{this.formatParam(calories)} ККал</TableCell>
                                </TableRow>
                                <TableRow key="prot">
                                    <TableCell className={classes.leftColumn}>Белки</TableCell>
                                    <TableCell className={classes.rightColumn}>{this.formatParam(prot)} г</TableCell>
                                </TableRow>
                                <TableRow key="fat">
                                    <TableCell className={classes.leftColumn}>Жиры</TableCell>
                                    <TableCell className={classes.rightColumn}>{this.formatParam(fat)} г</TableCell>
                                </TableRow>
                                <TableRow key="carb">
                                    <TableCell className={classes.leftColumn}>Углеводы</TableCell>
                                    <TableCell className={classes.rightColumn}>{this.formatParam(carb)} г</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Typography variant="h5" component="h3">
                            Дополнительно
                        </Typography>

                        <fieldset className={classes.waterBar}>
                            <legend className={classes.waterLabel}>Вода (мл.)</legend>
                            <Grid item xs={12} aria-label={12}>
                                <WideToggleButtonGroup
                                    value={water}
                                    exclusive
                                    onChange={this.handleWater}
                                    aria-label="text alignment"
                                    color="secondary"
                                    size="small"
                                >
                                    <WideToggleButton value="0" aria-label="0">
                                        0
                                    </WideToggleButton>
                                    <WideToggleButton value="100" aria-label="100">
                                        100
                                    </WideToggleButton>
                                    <WideToggleButton value="200" aria-label="200">
                                        200
                                    </WideToggleButton>
                                    <WideToggleButton value="300" aria-label="300">
                                        300
                                    </WideToggleButton>
                                    <WideToggleButton value="400" aria-label="400">
                                        400
                                    </WideToggleButton>
                                </WideToggleButtonGroup>
                            </Grid>
                        </fieldset>

                        <div>
                            <TextField
                                id="time"
                                inputRef={(ref) => this.timeInput = ref}
                                label="Время приема пищи"
                                margin="normal"
                                type="time"
                                defaultValue={currentTime}
                                className={classes.time}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            />
                        </div>

                        <div>
                            <TextField
                                id="comment"
                                inputRef={(ref) => this.commentInput = ref}
                                label="Комментарий к приему пищи"
                                margin="dense"
                                multiline={true}
                                defaultValue={comment}
                                rowsMax={3}
                                rows={1}
                                className={classes.comment}
                                variant="outlined"
                            />
                        </div>

                        <div className={classes.right}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleSave}
                            >
                                Отправить
                                <Icon className={classNames(classes.iconSmall, classes.rightIcon)}>send</Icon>
                            </Button>
                        </div>

                    </div>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(SummaryDialog);