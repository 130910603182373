// @flow
import Meal from "./Meal";

class MenuItem {

    id: number;
    meal: Meal;
    amount: number;
    comment: string;

    constructor(id: number, meal: Meal, amount: number, comment: string) {
        this.id = id;
        this.meal = meal;
        this.amount = amount;
        this.comment = comment;
    }
}

export default MenuItem;