//@flow

class Menu {

    items: any;
    comment: string;
    time: string;
    water: number;
    closed: boolean;


    constructor(items: *, comment: string, time: string, water: number, closed: boolean = false) {
        this.items = items;
        this.comment = comment;
        this.time = time;
        this.water = water;
        this.closed = closed;
    }
}

export default Menu;